<template>
    <div class="volunteer">
        <div class="info">
            <div class="title">志愿者</div>
            <div class="text">感谢你对TEDxSanya的关注，希望你能在一个专注的时刻真诚地进行填写，期待你的加入 ：）</div>
        </div>

        <div class="form">
            <div class="tip">
                <span>* </span>
                <span class="name">你的姓名</span>
            </div>
            <el-input    class="input" v-model="form.uname"></el-input>
            <div class="tip">
                <span>* </span>
                <span class="name">你的微信号</span>
            </div>
            <el-input    class="input" v-model="form.weixin"></el-input>
            <div class="tip">
                <span>* </span>
                <span class="name">你的手机号</span>
            </div>
            <div class="phone">
                <el-input    class="input" placeholder="请输入手机号" v-model="form.mobile"  maxlength="11">
                    <template slot="prepend">86｜</template>
                </el-input>
                <el-input    class="input" placeholder="请输入验证码" v-model="form.code"  maxlength="6">
                    <template slot="append">
                        <div @click="getSms" class="smsButton">{{ smsText }}</div>
                    </template>
                </el-input>
            </div>
            <div class="tip">
                <span>* </span>
                <span class="name">你的常用邮箱</span>
            </div>
            <div class="dec">我们将通过邮箱发送面试信息，请注意查收&及时回复</div>
            <el-input    class="input" v-model="form.mail"></el-input>

            <div class="questionList" v-for="item in questionList" :key="item.question_id">
                <div class="tip">
                    <span  :class="item.required == 1 ? '' : 'bgColor' ">* </span>
                    <span class="name">{{ item.title }}</span>
                </div>
                <div class="dec">{{ item.describe }}</div>
                <div class="textareaBox">
                    <el-input  maxlength="150"  class="input " :class="item.size == 2 ? 'info' : '' "  :type="item.size == 2 ? 'textarea' : '' " v-model="item.content"></el-input>
                    <div class="count" v-if="item.content">{{ item.content.length }}/150</div>
                </div>
            </div>

        </div>

        <div class="submit">
            <div class="text">
                <span>请申请内容创作、视频制作、摄影师的伙伴们把你过往的相关作品发送到以下邮箱：</span>
                <span class="color">tedxsanya@gmail.com</span></div>
            <div class="button" @click="submit">提交</div>
        </div>
    </div>
</template>

<script>
import {question , volunteer_apply , sms} from './../../api/api'
export default {
    data(){
        return {
            form : {
                mobile : '',
                code : '',
                uname : '',
                weixin : '',
                mail : '',
                question : '',
            },
            questionList : [],
            smsText: '获取验证码',
            timer: '',
            numberCount: 0
        }
    },
    methods : {
        //获取验证码
        getSms() {
            if (this.numberCount > 0) return
            if(!this.form.mobile){
                this.$message.error('请先输入手机号')
                return
            }
            var obj = {}
            obj.mobile = this.form.mobile
            obj.event = 1
            sms(obj).then(res => {
                this.numberCount = 60
                this.$message({ message: '验证码已发送', type: 'success' })
                this.timer = setInterval(() => {
                    if (this.numberCount <= 1) {
                        clearInterval(this.timer)
                        this.numberCount = 0
                        this.smsText = '获取验证码'
                        return
                    }
                    this.numberCount--
                    this.smsText = this.numberCount + 's'
                }, 1000)
            })
        },
        submit(){
            if(!this.form.weixin || !this.form.mobile || !this.form.code || !this.form.uname || !this.form.mail){
                this.$message.error(' 填完必填信息后，才能提交哦～')
                return
            }
            var arr = []
            for(var i = 0 ; i < this.questionList.length ; i ++){
                if(!this.questionList[i].content && this.questionList[i].required == 1){
                    this.$message.error(' 填完必填信息后，才能提交哦～')
                    return
                }
                var obj = {}
                obj.question_id = this.questionList[i].question_id
                obj.content = this.questionList[i].content
                obj.extend_content = this.questionList[i].extend_content || ''
                arr.push(obj)
            }
            
            this.form.question = JSON.stringify(arr)
            volunteer_apply(this.form).then(res => {
                if (res.data.code === '10000') {
                    this.$message({ message: '申请成功', type: 'success' })
                    this.form = {}
                    this.questionList.forEach(item => {
                        item.content = ''
                    })
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        getQuestion(){
            question({'role' : 2}).then(res => {
                if (res.data.code === '10000') {
                    this.questionList = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        }
    },
    mounted(){
        this.getQuestion()
    }
}
</script>

<style lang="less" scoped>
.volunteer{
    background: #1C1C1C;
    padding-bottom: 150px;
    overflow: hidden;
    .textareaBox{
        position: relative;
        .count{
            position: absolute;
            bottom: -25px;
            right: 0;
            font-size: 14px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 20px;
        }
    }
    .info{
        width: 770px;
        margin: 150px auto 0;
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            text-align: center;
        }
        .text{
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_85_Bold;
            color: #FFFFFF;
            line-height: 36px;
            margin-top: 50px;
        }
    }
    .form{
        width: 770px;
        margin: 20px auto 0;
        .input{
            width: 770px;
            height: 40px;
            background: rgba(216,216,216,0.3);
            border-radius: 10px;
            border: 1px solid #FFFFFF;
            margin-top: 10px;
            /deep/ .el-input__inner  , /deep/ .el-textarea__inner{
                height: 100%;
                background: transparent;
                outline: none;
                border: none;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 38px;
                padding: 0 14px;
                resize: none;
            }
            /deep/ .el-input-group__prepend{
                background: transparent;
                border: none;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 38px;
                padding: 0;
                text-indent: 14px;
            }
            /deep/ .el-input-group__append{
                width: 103px;
                height: 29px;
                background: #D8D8D8;
                border-radius: 8px;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #1C1C1C;
                line-height: 29px;
                border: none;
                padding: 0;
                text-align: center;
                display: block;
                position: absolute;
                right: 13px;
                top: 50%;
                margin-top: -15px;
                cursor: pointer;
            }
        }
        .info{
            height: 80px;
            /deep/ .el-textarea__inner{
                line-height: 30px;
            }
        }
        .tip{
            font-size: 20px;
            font-family: YouSheBiaoTiHei;
            color: #EB0028;
            line-height: 40px;
            margin-top: 30px;
            .name{
                color: #fff;
            }
        }
        .dec{
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 40px;
            text-indent: 14px;
        }
        .phone{
            display: flex;
            .input{
                width: 368px;
            }
            .input:nth-of-type(1){
                /deep/ .el-input__inner{
                    text-indent: 0;
                    padding: 0;
                }
            }
            .input:nth-of-type(2){
                margin-left: 34px;
            }
        }
        .file{
            margin-top: 10px;
            /deep/ .el-input__inner{
                padding: 0;
            }
            /deep/ .el-button{
                width: 106px;
                height: 50px;
                background: rgba(216,216,216,0.3);
                border-radius: 10px;
                border: 1px solid #FFFFFF;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 48px;
                padding: 0;
            }
        }
    }
    .submit{
        margin: 50px auto 0;
        width: 770px;
        .text{
            font-size: 20px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 34px;
            text-align: left;
            white-space: nowrap;
            span{
                display: block;
            }
            .color{
                color: #EB0028;
            }
        }
        .button{
            width: 106px;
            height: 50px;
            background: #EB0028;
            border-radius: 10px;
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_85_Bold;
            color: #FFFFFF;
            line-height: 50px;
            margin: 50px auto 0;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>