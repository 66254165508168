<template>
    <div class="assembly">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="info">
            <div class="box1">
                <img src="./../../assets/img/tedIcon.png" alt="" class="img1">
                <span>2023年度大会</span>
            </div>
            <div class="box2">
                2023年<span class="color">4</span>月<span class="color">15</span>日（周六），三亚·亚特兰蒂斯
                <img src="./../../assets/img/sanya1.png" alt="" class="img2">
            </div>
        </div>

        <div class="theme">
            <div class="title">
                <span>大会主题 </span>
                <span class="color">U</span>
            </div>
            <div class="content radius">
                <!-- <img :src="theme.cover" alt=""> -->
                <video :src="theme.url" :poster="theme.cover" controls controlslist="nodownload" disablePictureInPicture class="radius"></video>
            </div>
        </div>

        <div class="join">
            <div class="title">
                <span>报名购票</span>
                <img src="./../../assets/img/join.png" alt="">
            </div>
            <div class="button" @click="goAudience">获得门票</div>
            <div class="step">报名流程： ①报名申请 （需要审核）   ②审核完成后通过小程序通知购票付费   ③购票成功后，门票不得转让，不得退票</div>
        </div>

        <div class="speaker">
            <div class="titleName">
                <span>讲者阵容</span>
                <img src="./../../assets/img/speakerImg.png" alt="">
            </div>
            <div class="list">
                <img :src="talkerImg" alt="" class="radius">
                <div class="text">（排名不分先后）</div>
            </div>

            <div class="wrapper">
                <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">

                    <el-carousel-item v-for="page in Math.ceil(talker.length / 3) " :key="page" >
                        <div class="partnerBox">
                            <div class="speakerBox radius" v-if="talker[3 * (page - 1)]">
                                <img class="radius" :src="talker[3 * (page - 1)].avatar" alt="">
                            </div>
                            <div class="speakerBox radius center" v-if="talker[3 * (page - 1) + 1]">
                                <img class="radius" :src="talker[3 * (page - 1) + 1].avatar" alt="">
                            </div>
                            <div class="speakerBox radius" v-if="talker[3 * (page - 1) + 2]">
                                <img class="radius" :src="talker[3 * (page - 1) + 2].avatar" alt="">
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div class="schedule">
                <div class="title">
                    <span>日程安排</span>
                    <img src="./../../assets/img/schedule.png" alt="">
                </div>
                <div class="content">
                    <div class="left">
                        <img src="./../../assets/img/clock.png" alt="">
                        <div class="time">
                            <span>12:20-13:20</span>
                            <span>13:20-13:30</span>
                            <span>13:30-15:30</span>
                            <span>15:30-16:00</span>
                        </div>
                        <div class="name">
                            <span>注册入场</span>
                            <span>开幕主持</span>
                            <span>Session 1</span>
                            <span>休息</span>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="right">
                        <div class="time">
                            <span>16:00-18:00</span>
                            <span>18:00-18:30</span>
                            <span>18:30-19:00</span>
                            <span>19:00-23:00</span>
                        </div>
                        <div class="name">
                            <span>Session 2</span>
                            <span>闭幕主持</span>
                            <span>观众退场</span>
                            <span>After Party 嘉宾晚宴</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="place">
                <div class="title">
                    <span>活动地址</span>
                    <img src="./../../assets/img/placeIcon.png" alt="">
                </div>
                <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                    <el-carousel-item v-for="(item , index) in mapList" :key="index">
                        <div class="content">
                            <img :src="item" alt="" class="img radius">
                        </div>
                    </el-carousel-item>
                </el-carousel>
                
                <div class="placeTip">
                    <img src="./../../assets/img/placeTip.png" alt="">
                    <span>三亚·亚特兰蒂斯</span>
                </div>
            </div>
            <div class="partner">
                <div class="titleName">
                    <span>合作伙伴</span>
                    <img src="./../../assets/img/parterImg.png" alt="">
                </div>
                <div class="parterContent">
                    <div class="wrapperBox">
                        <div class="title">战略合作伙伴</div>
                        <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                            <el-carousel-item v-for="(item , index) in parterList" :key="index">
                                <div class="content">
                                    <img :src="item" alt="" class="img radius">
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="wrapperBox">
                        <div class="title">重要合作伙伴</div>
                        <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                            <el-carousel-item v-for="(item , index) in importantList" :key="index">
                                <div class="content">
                                    <img :src="item" alt="" class="img radius">
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="wrapperBox">
                        <div class="title">合作伙伴</div>
                        <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                            <el-carousel-item v-for="(item , index) in parter" :key="index">
                                <div class="content">
                                    <img :src="item" alt="" class="img radius">
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {banner , video , talker , partner , agm} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            talkerImg : '',
            mapList : [],
            parterList : [],
            parter : [],
            talker : [],
            importantList : [],
            theme : {}
        }
    },
    mounted(){
        banner({'seat' : 2 , agm_id : '2'}).then(res => {
            if (res.data.code === '10000') {
                if(res.data.data.length == 0) return
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
        talker({agm_id : '2'}).then(res => {
            if (res.data.code === '10000') {
                this.talker = res.data.data
            } else {
                this.$message.error(res.data.msg)
            }
        })
        //合作伙伴
        partner({device : 1,agm_id : '2' , type : '1'}).then(res => {
            if (res.data.code === '10000') {
                this.parter = res.data.data
            } else {
                this.$message.error(res.data.msg)
            }
        })
        //战略合作伙伴
        partner({device : 1,agm_id : '2' , type : '2'}).then(res => {
            if (res.data.code === '10000') {
                this.parterList = res.data.data
            } else {
                this.$message.error(res.data.msg)
            }
        })
        //重要合作伙伴
        partner({device : 1,agm_id : '2' , type : '3'}).then(res => {
            if (res.data.code === '10000') {
                this.importantList = res.data.data
            } else {
                this.$message.error(res.data.msg)
            }
        })
        //大会主题
        video({'type' : 1,agm_id : '2'}).then(res => {
            if (res.data.code === '10000') {
                this.theme = res.data.data[0]
            } else {
                this.$message.error(res.data.msg)
            }
        })
        //活动地址
        agm({agm_id : '2'}).then(res => {
            if (res.data.code === '10000') {
                this.mapList = res.data.data[1].address
                this.talkerImg = res.data.data[1].talker_pc
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        goAudience(){
            this.$router.push('/audience')
        }
    }
}
</script>
<style lang="less" scoped>
.assembly{
    background: #1C1C1C;
    .partner{
        width: 1126px;
        margin: 0 auto;
        overflow: hidden;
        padding-bottom: 130px;
        .wrapperBox{
            margin-top: 86px;
        }
        .title{
            font-size: 32px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 38px;
            text-align: center;
        }
        .parterContent{
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
        }
        .carousel{
            width: 360px;
            height: 395px;
            margin-top: 30px;
            /deep/ .el-carousel__container{
                height: 360px;
                .content{
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        /deep/ .el-carousel__indicator--horizontal{
            padding: 15px 4px 0;
        }
        .partnerBox{
            
            overflow: hidden;
            .partnerItem{
                width: 348px;
                height: 365px;
                background: #000000;
                float: left;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .partnerItem:nth-of-type(2){
                margin: 0 41px 0;
            }
        }
    }
    .schedule{
        width: 1126px;
        margin: 86px auto 0;
        overflow: hidden;
        .content{
            font-size: 20px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 44px;
            margin-top: 25px;
            display: flex;
            .line{
                width: 1px;
                height: 152px;
                background: #D8D8D8;
                border-radius: 2px;
                float: left;
                margin-left: 64px;
                margin-top : 12px;
            }
            .left{
                float: left;
                position: relative;
                margin-left: 47px;
            }
            .right{
                float: left;
                margin-left: 127px;
                position: relative;
            }
            span{
                display: block;
                white-space: nowrap;
            }
            .time{
                float: left;
            }
            .name{
                float: left;
                margin-left: 130px;
            }
            img{
                width: 26px;
                height: 26px;
                display: block;
                position: absolute;
                left: -47px;
            }
        }
        .title{
            font-size: 56px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 66px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 73px;
                height: 73px;
                margin-left: 16px;
            }
        }
    }
    .wrapper{
        height: 474px;
        margin-top: 30px;
        .carousel{
            height: 500px;
            /deep/ .el-carousel__container{
                height: 474px;
            }
        }
        /deep/ .el-carousel__indicator--horizontal{
            padding: 15px 4px 0;
        }
        .speakerBox{
            text-align: center;
            width: 348px;
            float: left;
            height: 474px;
            img{
                // width: 348px;
                // height: 237px;
                // margin-top: -2px;
                width: 100%;
                height: 100%;
            }
            .title{
                font-size: 22px;
                font-family: YouSheBiaoTiHei;
                color: #000000;
                line-height: 29px;
                margin: 14px auto 0;
            }
            .info{
                width: 281px;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #000000;
                line-height: 28px;
                margin: 9px auto 0;
                text-align: left;
            }
        }
        .center{
            margin: 0 41px 0;
        }
    }
    .speaker{
        width: 1126px;
        margin: 73px auto 0;
        .list{
            width: 1126px;
            // height: 445px;
            margin: 26px auto 0;
            img{
                width: 100%;
                height: 445px;
            }
            .text{
                font-size: 19px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 26px;
                margin-top: 10px;
                text-align: center;
            }
        }
        .titleName{
            font-size: 56px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 66px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 120px;
            img{
                width: 73px;
                height: 73px;
                margin-left: 16px;
            }
        }
        .place{
            width: 1126px;
            margin: 69px auto 0;
            overflow: hidden;
            .placeTip{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 25px;
                img{
                    width: 16px;
                    height: 24px;
                }
                span{
                    font-size: 20px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 28px;
                    margin-left: 16px;
                }
            }
            .carousel{
                height: 730px;
                margin: 20px auto 0;
                /deep/ .el-carousel__container{
                    height: 700px;
                }
            }
            .content{
                width: 100%;
                height: 700px;
                
                .img{
                    width: 100%;
                    height: 100%;
                    background: #9F9F9F;
                }
            }
            .title{
                font-size: 56px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 66px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 73px;
                    height: 73px;
                    margin-left: 16px;
                }
            }
        }
    }
    .join{
        width: 1126px;
        margin: 81px auto 0;
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            text-align: center;
            img{
                width: 43px;
                height: 26px;
                margin-left: 10px;
            }
        }
        .button{
            width: 136px;
            height: 64px;
            background: #EB0028;
            border-radius: 8px;
            font-size: 24px;
            font-family: AlibabaPuHuiTi_2_85_Bold;
            color: #FFFFFF;
            line-height: 64px;
            text-align: center;
            margin: 31px auto 30px;
            cursor: pointer;
        }
        .step{
            text-align: center;
            font-size: 20px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 40px;
        }
    }
    .theme{
        width: 1126px;
        margin: 46px auto 0;
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            text-align: center;
        }
        .color{
            color: #EB0028;
        }
        .content{
            width: 1126px;
            height: 634px;
            background: #9F9F9F;
            margin: 30px auto 0;
            img{
                width: 100%;
                height: 100%;
            }
            video{
                width: 100%;
                height: 100%;
            }
        }
    }
    .info{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 90px;
        .box1{
            display: flex;
            align-items: center;
            .img1{
                width: 196px;
                height: 36px;
            }
            span{
                font-size: 34px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 44px;
                margin-left: 18px;
            }
        }
        .box2{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
            display: flex;
            align-items: center;
            margin-top: 25px;
            .img2{
                width: 33px;
                height: 34px;
                margin-left: 9px;
            }
            .color{
                color: #EB0028;
                font-size: 34px;
            }
        }
    }
    .videoBox{
        // background-image: url('./../../assets/img/assemblyBanner.png');
        video{
            top: unset;
            bottom: 0;
        }
    }
}

</style>