<template>
	<div class="home">
		<div class="head">
			<div @click="goHome" class="homeNav">
				<img src="./../../assets/img/ted.png" alt="" >
			</div>
			<div class="nav">
				<div class="listItem join" :class="(routeName == 'assembly' || routeName == 'previousTedx') ? 'active' : '' ">
					<span @click="goAssembly">年度大会</span>
					<i :class=" openAssembly ? 'el-icon-arrow-up' :  'el-icon-arrow-down'" @click.stop="openAssembly = !openAssembly"></i>
					<div class="childRoot AssemblyRoot" v-if="openAssembly">
						<div class="root"  :class="routeName == 'previousTedx' ? 'active' : '' " @click="nextPage(5)">2023年度大会</div>
					</div>
				</div>
				<div class="listItem" :class="routeName == 'video' ? 'active' : '' " @click="goVideo">视频</div>
				<div class="listItem join" :class="(routeName == 'join' || routeName == 'speaker' || routeName == 'partner' || routeName == 'volunteer' || routeName == 'audience') ? 'active' : '' " >
					<span @click="goJoin">参与</span>
					<i :class=" open ? 'el-icon-arrow-up' :  'el-icon-arrow-down'" @click.stop="open = !open"></i>
					<div class="childRoot" v-if="open">
						<div class="root"  :class="routeName == 'speaker' ? 'active' : '' " @click="nextPage(1)">成为讲者</div>
						<div class="root"  :class="routeName == 'partner' ? 'active' : '' " @click="nextPage(2)">合作伙伴</div>
						<div class="root"  :class="routeName == 'volunteer' ? 'active' : '' " @click="nextPage(3)">志愿者</div>
						<div class="root"  :class="routeName == 'audience' ? 'active' : '' " @click="nextPage(4)">观众</div>
					</div>
				</div>
				<div class="listItem about" :class="routeName == 'about' ? 'active' : '' " @click="goAbout">关于</div>
				<div class="listItem" :class="routeName == 'contact' ? 'active' : '' " @click="goContact">联系我们</div>
			</div>
		</div>
		<router-view></router-view>
		<div class="footer">
			<img src="./../../assets/img/ted.png" alt="" class="img">
			<div class="textBox">
				<div class="text">
					<a href="https://beian.miit.gov.cn">琼ICP备2022019390号</a>
					<span> ｜ </span>
					<img src="./../../assets/img/beian.png" alt="" class="beian">
					<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46020402000177" style="display:inline-block;"><img src="" style="float:left;"/>琼公网安备 46020402000177号</a>
					<span @click="goContact"> ｜ 联系我们</span></div>
				<div class="text2">@ 2023 Copyright-TEDxSanya | An Independently Organized TEDx Event</div>
			</div>
		</div>
		<div class="kefu" @click="showCode">
			<img src="./../../assets/img/kefu.png" alt="" class="kefuImg">
			<img src="./../../assets/img/kefucode.png" alt="" class="kefucodeImg" v-if="wxcode">
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			routeName : '',
			wxcode : false,
			openAssembly : false,
			open : false
		}
	},
	methods : {
		showCode(){
			this.wxcode = !this.wxcode
		},
		goAssembly(){
            this.$router.push('/assembly')
        },
		goHome(){
			this.$router.push('/assembly')
		},
		goVideo(){
			this.$router.push('/video')
		},
		goJoin(){
			this.$router.push('/join')
		},
		goAbout(){
			this.$router.push('/about')
		},
		goContact(){
			this.$router.push('/contact')
		},

		nextPage(type){
			if(type == 1){
				this.$router.push('/speaker')
			}else if(type == 2){
				this.$router.push('/partner')
			}else if(type == 3){
				this.$router.push('/volunteer')
			}else if(type == 4){
				this.$router.push('/audience')
			}else if(type == 5){
				this.$router.push('/previousTedx')
			}
		}
	},
	mounted(){
		document.addEventListener('click' , () => {
			this.open = false
			this.openAssembly = false
		})
		this.routeName = this.$route.name
	},
	watch : {
		"$route" : function (){
			this.routeName = this.$route.name
			this.wxcode = false
		}
	}
}
</script>
<style lang="less" scoped>
.home{
	background: #000000;
	min-width: 1400px;
	position: relative;
	.kefu{
		width: 56px;
		height: 56px;
		background: #EC0027;
		border-radius: 50%;
		position: fixed;
		right: 29px;
		bottom: 91px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
		.kefuImg{
			width: 32px;
			height: 32px;
		}
		.kefucodeImg{
			position: absolute;
			bottom: 41px;
			right: 51px;
			width: 158px;
			height: 277px;
		}
	}
	// padding-top: 144px;
	.footer{
		margin: 0 124px 0 199px;
		height: 152px;
		.img{
			width: 259px;
			height: 47px;
			margin-top: 58px;
			float: left;
		}
		.textBox{
			font-size: 16px;
			font-family: AlibabaPuHuiTi_2_55_Regular;
			color: #FFFFFF;
			line-height: 20px;
			float: right;
			text-align: right;
			margin-top: 49px;
			white-space: nowrap;
			a{
				font-size: 16px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				color: #FFFFFF;
				line-height: 20px;
				text-decoration:none;
			}
			.beian{
				width: 18px;
				height: 20px;
				vertical-align: bottom;
				margin-right: 8px;
			}
		}
		.text2{
			margin-top: 11px;
		}
	}
	.head{
		// background: #000;
		width: 100%;
		min-width: 1400px;
		height: 144px;
		position: absolute;
		top: 0;
		z-index: 99;
		.nav{
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			right: 186px;
			.listItem{
				font-size: 22px;
				font-family: YouSheBiaoTiHei;
				color: #FFFFFF;
				line-height: 29px;
				float: left;
				margin-left: 22px;
				cursor: pointer;
				text-align: center;
				position: relative;
				i{
					position: absolute;
					font-size: 12px;
					line-height: 29px;
					right: 0;
					transition: 0.2s;
					width: 12px;
					text-indent: 0;
					color: #fff;
				}
				.childRoot{
					width: 127px;
					padding: 12px 0;
					background: #000000;
					border-radius: 8px;
					border: 1px solid #FFFFFF;
					font-size: 18px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					color: #FFFFFF;
					line-height: 25px;
					text-align: center;
					text-indent: 0;
					margin-top: 5px;
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					.root:hover{
						color: #3DA6D5;
					}
					.root{
						margin-top: 22px;
					}
					.root:nth-of-type(1){
						margin-top: 0;
					}
					// .root:nth-last-of-type(1){
					// 	margin-top: 26px;
					// }
				}
				.RegisterRoot{
					height: 108px;
				}
			}
			.active{
				color: #EB0028;
			}
			.join{
				// width: 62px;
				margin-left: 20px;
				text-align: left;
				padding-right: 20px;
				position: relative;
			}
		}
		.homeNav{
			width: 259px;
			height: 47px;
			margin-top: 54px;
			float: left;
			cursor: pointer;
			position: absolute;
			left: 200px;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
