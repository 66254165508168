<template>
    <div class="Massembly">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="videoBoxtitle">{{ data.title }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true"></video>
        </div>
        <div class="headInfo">
            <img src="./../../assets/img/Mted.png" alt="" class="img1" />
            <div class="spanBox">
                <div class="span1">2023年度大会</div>
                <img src="./../../assets/img/tree.png" alt="" class="img2" />
            </div>
            
        </div>

        <div class="theme">
            <div class="videoContent">
                <video
                class="contentVideo"
                :src="theme.url"
                :poster="theme.cover"
                controls
                controlslist="nodownload"
                disablePictureInPicture
                @ended="videoEnd"
                ></video>
            </div>
        </div>


        <div class="speaker">
            <div class="titleName">
                <span>讲者阵容</span>
                <img src="./../../assets/img/speaker.png" alt="" class="speakerImg">
            </div>
            <div class="wrapper"  @touchstart="touchstartFn" @touchend="touchendFn">
                <div class="dots">
                    <div :class="Current1 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in talker"   :key="index"></div>
                </div>
                <el-carousel @change="change1" ref="carousel"  class="carousel" :interval="5000" arrow="never" indicator-position="none">
                    <el-carousel-item v-for="(item , index) in talker" :key="index">
                        <div class="speakerBox">
                            <img :src="item.avatar" alt="" class="radius">
                            <!-- <div class="title">{{ item.tag }}</div>
                            <div class="info">{{ item.profile }}</div> -->
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div class="list">
                <img :src="talkerImg" alt="" class="radius">
                <div class="text">（排名不分先后）</div>
            </div>

            <div class="schedule">
                <div class="title">
                    <span>活动日程</span>
                    <img src="./../../assets/img/schedule.png" alt="">
                </div>
                <div class="content">
                    <div class="timelist" v-for="(item , index) in step" :key="index">
                        <div class="lineCut" v-if="index != 0"></div>
                        <div class="listBox">
                            <div class="clock">
                                <img src="https://www.collegedirectedu.com/uploads/wxapp/img/clock.png" alt=""></img>
                                <span>{{ item.time }}</span>
                            </div>
                            <div class="stepName">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="place">
                <div class="title">
                    <span>活动回顾</span>
                    <img src="./../../assets/img/review.png" alt="">
                </div>
                <div class="mapContent"  @touchstart="touchstartFn3" @touchend="touchendFn3" >
                    <div class="dots">
                        <div :class="Current2 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in review" :key="index"></div>
                    </div>
                    <el-carousel ref="carouselMap" @change="change2" class="carouselMap" :interval="5000" arrow="never" indicator-position="none">
                        <el-carousel-item v-for="(item , index) in review" :key="index">
                            <div class="content">
                                <img :src="item" alt="" class="img radius">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                
                
                <div class="placeTip">
                    <img src="./../../assets/img/placeTip.png" alt="">
                    <span>三亚·亚特兰蒂斯</span>
                </div>
            </div>

        </div>
        <div class="titleName">
            <span>合作伙伴</span>
            <img src="./../../assets/img/parterImg.png" alt="">
        </div>
        <div class="partner">
        <div class="title">战略合作伙伴</div>
        <div
            class="partnerBox"
            @touchstart="touchstartFn2"
            @touchend="touchendFn2"
        >
            <div class="dots">
            <div
                :class="Current2 == index ? 'activeDots dot' : 'dot'"
                v-for="(item, index) in parterList"
                :key="index"
            ></div>
            </div>
            <el-carousel
            @change="change2"
            ref="carouselParter2"
            class="carousel"
            :interval="5000"
            arrow="never"
            indicator-position="none"
            >
            <el-carousel-item v-for="(item, index) in parterList" :key="index">
                <div class="partnerItem">
                <img :src="item" alt="" />
                </div>
            </el-carousel-item>
            </el-carousel>
        </div>
        <div class="title">重要合作伙伴</div>
        <div
            class="partnerBox important_partnerBox"
            @touchstart="touchstartFn5"
            @touchend="touchendFn5"
        >
            <div class="dots">
            <div
                :class="Current5 == index ? 'activeDots dot' : 'dot'"
                v-for="(item, index) in important_partner_mini"
                :key="index"
            ></div>
            </div>
            <el-carousel
            @change="change5"
            ref="carouselParter5"
            class="carousel"
            :interval="5000"
            arrow="never"
            indicator-position="none"
            >
            <el-carousel-item v-for="(item, index) in important_partner_mini" :key="index">
                <div class="partnerItem">
                <img :src="item" alt="" />
                </div>
            </el-carousel-item>
            </el-carousel>
        </div>
        <div class="title">合作伙伴</div>
        <div
            class="partnerBox"
            @touchstart="touchstartFn4"
            @touchend="touchendFn4"
        >
            <div class="dots">
            <div
                :class="Current4 == index ? 'activeDots dot' : 'dot'"
                v-for="(item, index) in parter"
                :key="index"
            ></div>
            </div>
            <el-carousel
            ref="carouselParter4"
            @change="change4"
            class="carousel"
            :interval="5000"
            arrow="never"
            indicator-position="none"
            >
            <el-carousel-item v-for="(item, index) in parter" :key="index">
                <div class="partnerItem">
                <img :src="item" alt="" class="img" />
                </div>
            </el-carousel-item>
            </el-carousel>
        </div>
        </div>
        <div class="bottomTip">
            This Independent TEDx event is operated under license from TED
        </div>
    </div>
</template>

<script>
import {banner , video , talker , partner , agm} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                video_url : '',
            },
            load : false,
            startMove : 0,
            startMove2 : 0,
            startMove3 : 0,
            startMove4 : 0,
            startMove5 : 0,
            Current1 : 0,
            Current2 : 0,
            Current3 : 0,
            Current4 : 0,
            Current5 : 0,
            talkerImg : '',
            mapList : [],
            parterList : [],
            parter : [],
            talker : [],
            theme : {},
            important_partner_mini : [],
            review : [],
            step: [{
				time: '12:20-13:20',
				name: '注册入场',
			},
			{
				time: '13:20-13:30',
				name: '开幕主持',
			},
			{
				time: '13:30-15:30',
				name: 'Session 1',
			},
			{
				time: '15:30-16:00',
				name: '休息',
			},
			{
				time: '16:00-18:00',
				name: 'Session 2',
			},
			{
				time: '18:00-18:30',
				name: '闭幕主持',
			},
			{
				time: '18:30-19:00',
				name: '观众退场',
			},{
				time: '19:00-23:00',
				name: 'After Party 嘉宾晚宴',
			},
		],
        }
    },
    mounted(){
        this.talkerImg = JSON.parse(localStorage.config).talker_mini.value[0]
        this.mapList = JSON.parse(localStorage.config).activity_address.value
        this.parterList = JSON.parse(localStorage.config).strategic_partner.value
        // this.parter = JSON.parse(localStorage.config).partner_mini.value
        this.important_partner_mini = JSON.parse(localStorage.config).important_partner_mini.value
        agm({agm_id : '2'}).then(res => {
            if (res.data.code === '10000') {
                this.review = res.data.data[0].review
            } else {
                this.$message.error(res.data.msg)
            }
        })
        banner({'seat' : 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                setTimeout(() => {
                    this.load = true
                } , 800)
            } else {
                this.$message.error(res.data.msg)
            }
        })
        talker().then(res => {
            if (res.data.code === '10000') {
                this.talker = res.data.data
            } else {
                this.$message.error(res.data.msg)
            }
        })
        partner({device : 2}).then(res => {
            if (res.data.code === '10000') {
                this.parter = res.data.data
            } else {
                this.$message.error(res.data.msg)
            }
        })
        //大会主题
        video({'type' : 1}).then(res => {
            if (res.data.code === '10000') {
                this.theme = res.data.data[0]
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        videoEnd() {
            let myVideo = document.querySelector(".contentVideo");
            myVideo.currentTime = 0.5;
		},
        change5(Current5){
            this.Current5 = Current5
        },
        //手机滑动切换
        touchstartFn5(e){
            this.startMove5 = e.changedTouches[0].clientX
        },
        touchendFn5(e){
            if(e.changedTouches[0].clientX - this.startMove5 > 50 ){
                this.changePage5(-1)
            }else if(e.changedTouches[0].clientX - this.startMove5 < -50){
                this.changePage5(1)
            }
        },
        changePage5(page){
            if(page > 0){
                this.$refs.carouselParter5.next()
            }else{
                this.$refs.carouselParter5.prev()
            }
        },
        change1(Current1){
            this.Current1 = Current1
        },
        change2(Current2){
            this.Current2 = Current2
        },
        change3(Current3){
            this.Current3 = Current3
        },
        change4(Current4){
            this.Current4 = Current4
        },
        //手机滑动切换
        touchstartFn4(e){
            this.startMove4 = e.changedTouches[0].clientX
        },
        touchendFn4(e){
            if(e.changedTouches[0].clientX - this.startMove4 > 50 ){
                this.changePage4(-1)
            }else if(e.changedTouches[0].clientX - this.startMove4 < -50){
                this.changePage4(1)
            }
        },
        changePage4(page){
            if(page > 0){
                this.$refs.carouselParter4.next()
            }else{
                this.$refs.carouselParter4.prev()
            }
        },


        //手机滑动切换
        touchstartFn3(e){
            this.startMove3 = e.changedTouches[0].clientX
        },
        touchendFn3(e){
            if(e.changedTouches[0].clientX - this.startMove3 > 50 ){
                this.changePage3(-1)
            }else if(e.changedTouches[0].clientX - this.startMove3 < -50){
                this.changePage3(1)
            }
        },
        changePage3(page){
            if(page > 0){
                this.$refs.carouselMap.next()
            }else{
                this.$refs.carouselMap.prev()
            }
        },
        //手机滑动切换
        touchstartFn2(e){
            this.startMove2 = e.changedTouches[0].clientX
        },
        touchendFn2(e){
            if(e.changedTouches[0].clientX - this.startMove2 > 50 ){
                this.changePage2(-1)
            }else if(e.changedTouches[0].clientX - this.startMove2 < -50){
                this.changePage2(1)
            }
        },
        changePage2(page){
            if(page > 0){
                this.$refs.carouselParter2.next()
            }else{
                this.$refs.carouselParter2.prev()
            }
        },
        changePage(page){
            if(page > 0){
                this.$refs.carousel.next()
            }else{
                this.$refs.carousel.prev()
            }
        },
        //手机滑动切换
        touchstartFn(e){
            this.startMove = e.changedTouches[0].clientX
        },
        touchendFn(e){
            if(e.changedTouches[0].clientX - this.startMove > 50 ){
                this.changePage(-1)
            }else if(e.changedTouches[0].clientX - this.startMove < -50){
                this.changePage(1)
            }
        },
    }
}
</script>

<style lang="less" scoped>
.Massembly{
    background: #1C1C1C;
    text-align: center;
    padding-bottom: 50px;
    .bottomTip{
        width: 331px;
        font-family: YouSheBiaoTiHei;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 18px;
        text-align: center;
        margin: 46px auto 0;
    }
    .parterTitle{
        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        line-height: 31px;
        margin-top: 48px;
    }
    .partner {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	.partnerBox {
		width: 100%;
		margin-top: 7px;
		position: relative;
		height:331px;
		.content {
		height: 100%;
		overflow: hidden;
		}
		.carousel {
			width: 331px;
			height: 100%;
			margin: 0 auto 0;
			/deep/ .el-carousel__container {
			height: 100%;
			}
		}
		.partnerItem {
			width: 331px;
			height: 100%;
			background: #000000;
			float: left;
			border-radius: 8px;
			img {
			width: 100%;
			height: 100%;
			border-radius: 8px;
			}
		}
	}
	.title {
		margin-top: 30px;
		font-size: 20px;
		font-family: YouSheBiaoTiHei;
		color: #ffffff;
		line-height: 26px;
	}
	.title:nth-of-type(1){
		margin-top: 16px;
	}
}
    .speaker{
        margin-top: 48px;
        .place{
            margin-top: 40px;
            .mapContent{
                position: relative;
            }
            .placeTip{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 20px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin-top: 34px;
                img{
                    width: 12px;
                    height: 18px;
                    margin-right: 6px;
                    margin-bottom: 3px;
                }
            }
            .carouselMap{
                width: 337px;
                height: 206px;
                margin: 19px auto 0;
                /deep/ .el-carousel__container{
                    width: 331px;
                    height: 100%;
                    margin: 0 auto;
                }
            }
            .content{
                height: 100%;
                overflow: hidden;
                .img{
                    width: 100%;
                    height: 100%;
                    background: #9F9F9F;
                }
            }
            .title{
                font-size: 24px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 31px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 32px;
                    height: 32px;
                    margin-left: 7px;
                }
            }
        }
        
        .list{
            width: 331px;
            margin: 51px auto 0;
            img{
                width: 100%;
                // height: 1039px;
            }
            .text{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 20px;
                margin-top: 15px;
            }
        }
        .wrapper{
            
            height: 450px;
            
            position: relative;
            /deep/ .el-carousel__container{
                height: 450px;
                width: 331px;
            }
            .carousel{
                height: 450px;
                width: 337px;
                margin: 21px auto 0;
            }
            .speakerBox{
                width: 331px;
                height: 450px;
                margin: 0 auto;
                img{
                    // width: 333px;
                    // height: 226px;
                    // margin-top: -2px;
                    // margin-left: -1px;
                    width: 100%;
                    height: 100%;
                }
                .title{
                    font-size: 16px;
                    font-family: YouSheBiaoTiHei;
                    color: #000000;
                    line-height: 21px;
                    margin-top: 22px;
                }
                .info{
                    width: 267px;
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #000000;
                    line-height: 28px;
                    margin: 15px auto 0;
                    text-align: left;
                }
            }
        }
        
    }
    .titleName{
        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        line-height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 48px;
        img{
            width: 32px;
            height: 32px;
            margin-left: 8px;
        }
        .speakerImg{
            width: 18px;
            height: 24px;
            margin-left: 15px;
        }
    }
    .theme{
        margin: 13px auto 0;
        width: 331px;
        .title{
            font-size: 26px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 34px;
            .color{
                color: #EB0028;
            }
        }
        .content{
            width: 331px;
            height: 187px;
            // background: #9F9F9F;
            margin: 30px auto 0;
            img{
                width: 100%;
                height: 100%;
            }
            video{
                width: 100%;
                height: 100%;
            }
        }
    }
    .MvideoBox{
        // background-image: url('./../../assets/img/assemblyBanner.png');
        video{
            top: unset;
            bottom: 0;
        }
    }
}
.headInfo {
	margin-top: 32px;
	display: flex;
	align-items: center;
	flex-direction: column;
    .span1{
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        line-height: 29px;
        margin-top: 2px;
    }
    .img2{
        width: 26px;
        height: 27px;
        margin-left: 13px;
    }
    .spanBox{
        display: flex;
        margin-top: 12px;
    }
}

.headInfo .img1 {
	width: 155px;
	height: 28px;
}

.videoContent {
	width: 331px;
	height: 186px;
	border-radius: 8px;
}

.videoContent video {
	width: 100%;
	height: 100%;
	border-radius: 8px;
}
.schedule {
	margin: 70px auto 0;
}

.schedule .content {
	width: 331px;
	margin: 24px auto 0;
}

.schedule .title {
	font-size: 24px;
	font-family: YouSheBiaoTiHei;
	color: #FFFFFF;
	line-height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 32px;
        height: 32px;
        margin-left: 8px;
    }
}

.timelist .lineCut {
	width: 2px;
	height: 13px;
	opacity: 0.6;
	background: #fff;
	margin-top: 10px;
	margin-bottom: 8px;
	margin-left: 9px;
}

.listBox {
	display: flex;
	align-items: center;
}

.clock {
	font-size: 18px;
	font-family: YouSheBiaoTiHei;
	color: #fff;
	line-height: 23px;
	display: flex;
	align-items: center;
	width: 207px;
}

.clock img {
	width: 20px;
	height: 20px;
	margin-right: 24px;
}

.stepName {
	font-size: 14px;
	font-family: AlibabaPuHuiTi_2_55_Regular;
	color: #FFFFFF;
	line-height: 20px;
    white-space: nowrap;
}
</style>