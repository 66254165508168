<template>
	<div class="home">
		<div class="head">
			<div @click="goHome" class="homeNav">
				<img src="./../../assets/img/ted.png" alt="">
			</div>
			<div class="tab" @click="drawer = true">
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
			</div>
		</div>
		<router-view></router-view>
		<div class="footer" :class="system == 'ios' ? 'iosHome' : ''">
			<div class="text">琼ICP备2022019390号 </div>
			<div class="line"><img src="./../../assets/img/beian.png" alt="" class="beian">琼公网安备 46020402000177号 ｜ <span
					@click="goContact">联系我们</span></div>
			<div class="text2">@ 2023 Copyright-TEDxSanya | An Independently Organized TEDx Event</div>
		</div>
		<el-drawer title="" :size="215" @close="close" :visible.sync="drawer">
			<div class="root">
				<div class="route" @click="goAssembly">
					<span>年度大会</span>
					<i :class="openAssembly ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click.stop="openAssembly = !openAssembly"></i>
				</div>
				<div class="childRoute AssemblyRoute" v-show="openAssembly">
					<div class="nextRoute" @click="nextPage(5)">2023年度大会</div>
				</div>
				<div class="route" @click="goVideo">视频</div>
				<div class="route">
					<span @click="goJoin">参与</span>
					<i :class="open ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click.stop="open = !open"></i>
				</div>
				<div class="childRoute" v-show="open">
					<div class="nextRoute" :class="routeName == 'Mspeaker' ? 'active' : ''" @click="nextPage(1)">成为讲者</div>
					<div class="nextRoute" :class="routeName == 'Mpartner' ? 'active' : ''" @click="nextPage(2)">合作伙伴</div>
					<div class="nextRoute" :class="routeName == 'Mvolunteer' ? 'active' : ''" @click="nextPage(3)">志愿者
					</div>
					<div class="nextRoute" :class="routeName == 'Maudience' ? 'active' : ''" @click="nextPage(4)">观众</div>
				</div>
				<div class="route" :class="open ? 'openRoute' : ''" @click="goAbout">关于</div>
				<div class="route" @click="goContact">联系我们</div>
				<div class="language">中文/English</div>
			</div>

		</el-drawer>
		<div class="kefu" @click="showCode">
			<img src="./../../assets/img/kefu.png" alt="" class="kefuImg">
		</div>
		<div class="accountBox" v-if="wxcode">
			<img class="zuweihuiImg" src="./../../assets/img/zuweihui.png" alt="" />
			<div class="text1">
				<div>扫描二维码</div>
				<div>添加官方组委会</div>
			</div>
			<div class="text2">
				<div>探索最新资讯</div>
				<div>开启TEDxSanya之旅</div>
			</div>
			<div @click="closeaccount">
				<img src="https://www.tedxsanya.com/uploads/wxapp/img/close.png" alt=""  class="closeImg" />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			drawer: false,
			open: false,
			openAssembly: false,
			wxcode : false,
			system: 'android',
			routeName: ''
		}
	},
	created() {
		this.isAndroidOrIOS()
	},
	methods: {
		closeaccount(){
			this.wxcode = false
		},
		showCode(){
			this.wxcode = !this.wxcode
		},
		isAndroidOrIOS() {
			var u = navigator.userAgent;
			var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			if (isiOS) {
				this.system = 'ios'
			}
		},
		goAssembly() {
			this.drawer = false
			this.$router.push('/Massembly')
		},
		goHome() {
			// this.drawer = false
			this.$router.push('/Massembly')
		},
		goVideo() {
			this.drawer = false
			this.$router.push('/Mvideo')
		},
		goJoin() {
			this.drawer = false
			this.$router.push('/Mjoin')
		},
		goAbout() {
			this.drawer = false
			this.$router.push('/Mabout')
		},
		goContact() {
			this.drawer = false
			this.$router.push('/Mcontact')
		},
		nextPage(type) {
			this.drawer = false
			if (type == 1) {
				this.$router.push('/Mspeaker')
			} else if (type == 2) {
				this.$router.push('/Mpartner')
			} else if (type == 3) {
				this.$router.push('/Mvolunteer')
			} else if (type == 4) {
				this.$router.push('/Maudience')
			}else if (type == 5) {
				this.$router.push('/MpreviousTedx')
			}
		},
		close() {
			this.open = false
		}
	},
	watch: {
		"$route": function () {
			this.routeName = this.$route.name
			this.wxcode = false
		}
	}
}
</script>

<style lang="less" scoped>
.home {
	padding-top: 64px;
	.kefu{
		width: 50px;
		height: 50px;
		background: #EC0027;
		border-radius: 50%;
		position: fixed;
		right: 18px;
		bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
		.kefuImg{
			width: 32px;
			height: 32px;
		}
	}
	.accountBox{
		position: fixed;
		z-index: 99999;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		background: rgba(64, 64, 64, 0.8);
		text-align: center;
		.zuweihuiImg{
			width: 331px;
			height: 147px;
			margin: 273px auto 0;
		}
		.text1{
			font-size: 18px;
			font-family: YouSheBiaoTiHei;
			color: #FFFFFF;
			line-height: 25px;
			margin: 20px auto 0;
		}
		.text2{
			font-size: 12px;
			font-family: AlibabaPuHuiTi_2_55_Regular;
			color: #FFFFFF;
			line-height: 20px;
			margin: 15px auto 0;
		}
		.closeImg{
			width: 32px;
			height: 32px;
			margin: 18px auto 0;
		}
	}
	/deep/ .el-drawer {
		background: #1C1C1C;

		.account {
			position: absolute;
			left: 14px;
			bottom: 33px;

			img {
				width: 23px;
				height: 24px;
			}

			.accountBox {
				font-size: 18px;
				font-family: YouSheBiaoTiHei;
				color: #FFFFFF;
				line-height: 23px;
				margin-top: 24px;
			}
		}

		.root {
			margin-top: 31px;
			overflow: hidden;
			.route {
				width: 183px;
				height: 45px;
				border-bottom: 1px solid #4E4E4E;
				font-size: 18px;
				font-family: YouSheBiaoTiHei;
				color: #FFFFFF;
				line-height: 44px;
				margin: 0 auto 0;
				position: relative;
				i {
					text-indent: 0;
					font-size: 14px;
					line-height: 44px;
					position: absolute;
					right: 0;
				}
			}

			.openRoute {
				margin-top: 18px;
			}

			.childRoute {
				font-size: 14px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				color: #FFFFFF;
				line-height: 20px;
				width: 183px;
				margin: 8px auto 16px;
				.nextRoute{
					margin-top: 16px;
				}
				.nextRoute:nth-of-type(1){
					margin-top: 0;
				}
			}
		}

		.el-drawer__header {
			color: #fff;
			padding: 0;
			margin: 0;

			.el-drawer__close-btn {
				font-size: 16px;
				width: 13px;
				height: 13px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 29px;
				margin-right: 25px;
			}
		}
		.language{
			font-size: 14px;
			font-family: YouSheBiaoTiHei;
			color: #FFFFFF;
			line-height: 18px;
			position: absolute;
			right: 22px;
			bottom: 48px;
		}
	}

	.head {
		width: 100%;
		height: 64px;
		background: #000000;
		position: fixed;
		top: 0;
		z-index: 9999;

		.homeNav {
			width: 141px;
			height: 25px;
			margin-top: 20px;
			margin-left: 24px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.tab {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 22px;

			.line {
				width: 20px;
				height: 2px;
				background: #FFFFFF;
				border-radius: 1px;
			}

			.line:nth-of-type(2) {
				margin: 5px 0;
			}
		}
	}

	.footer {
		width: 100%;
		height: 102px;
		background: #000000;
		font-size: 10px;
		font-family: AlibabaPuHuiTi_2_55_Regular;
		color: #FFFFFF;
		line-height: 22px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		overflow: hidden;
		box-sizing: content-box;
		.line {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.beian {
			width: 10px;
			height: 11px;
			margin-right: 5px;
		}
	}
}

.iosHome {
	padding-bottom: 75px;
}
</style>