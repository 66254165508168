<template>
    <div class="about">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url"  loop muted ref="video" autoplay></video>
        </div>
        <div class="team">
            <div class="title">团队</div>
            <img src="./../../assets/img/team.png" alt="">
            <div class="name">Hang Qin</div>
            <div class="name">TEDxSanya大会策展负责人</div>
        </div>
        <div class="content">
            <div class="ted">
                <div class="title">关于TED</div>
                <div class="info">
                    <div class="img">
                        <img class="radius" src="./../../assets/img/about1.png" alt="">
                        <span>Credit to: TED.com</span>
                    </div>
                    <div class="text">
                        <span class="color">TED</span>
                        大会在1984年创立于加州，发展至今，大会传播分享的内容涵盖科学、商业、艺术、环境气候等领域。
                        <span class="color">TED</span>
                        以 Ideas Worth Spreading 为宗旨，通过精炼有力量的十几分钟演讲在全世界范围内传播有价值的思想，激发有意义的对话。
                    </div>
                </div>
            </div>
            <div class="ted">
                <div class="title">关于TEDx</div>
                <div class="info">
                    <div class="img">
                        <img class="radius" src="./../../assets/img/about2.png" alt="">
                        <span>Credit to: TEDxWinterPark</span>
                    </div>
                    <div class="text">
                        2009年TED发展出了
                        <span class="color">TEDx</span>
                        项目。x代表了在TED总部授权下独立组织的策展活动，
                        <span class="color">TEDx</span>
                        活动在全球各地开展，发现并传播有价值的观点和思想。
                    </div>
                </div>
            </div>
            <div class="ted">
                <div class="title">关于TEDxSanya</div>
                <div class="info">
                    <div class="img">
                        <img class="radius" src="./../../assets/img/about3.png" alt="">
                        <!-- <span>Credit to: Gilberto Tadday TED</span> -->
                    </div>
                    <div class="text">
                        <span class="color">TEDxSanya</span>
                        是经TED总部官方授权的全球TEDx社区成员，作为一个城市级TEDx组织，我们始于海南三亚，通过开展演讲活动、共创
                        <span class="color">TEDxSanya</span>
                        社区等方式，面向全国以及全球分享并传播有价值的创新观点。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false
        }
    },
    mounted(){
        banner({'seat' : 5}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
                
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        canplay(){
            this.$refs.video.play()
        }
    }
}
</script>

<style lang="less" scoped>
.about{
    background: #1C1C1C;
    .content{
        width: 1126px;
        margin: 0 auto;
        overflow: hidden;
        padding-bottom: 138px;
        .ted{
            margin-top: 71px;
            .info{
                margin-top: 24px;
                overflow: hidden;
                display: flex;
                align-items: center;
                .text{
                    font-size: 20px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 40px;
                    margin-left: 33px;
                    .color{
                        color: #EB0028;
                    }
                }
                .img{
                    float: left;
                    img{
                        width: 517px;
                        height: 252px;
                    }
                    span{
                        font-size: 14px;
                        font-family: YouSheBiaoTiHei;
                        color: #EB0028;
                        line-height: 18px;
                        display: block;
                        text-align: right;
                    }
                }
            }
            .title{
                font-size: 34px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 44px;
                text-align: center;
            }
        }
        .ted:nth-last-of-type(1){
            margin-top: 76px;
        }
    }
    .team{
        text-align: center;
        margin: 80px auto 0;
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
        }
        img{
            width: 200px;
            height: 200px;
            margin: 53px auto 19px;
        }
        .name{
            font-size: 22px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 40px;
        }
    }
    .videoBox{
        // background-image: url('./../../assets/img/aboutBanner.png');
        video{
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>