<template>
    <div class="contact">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="content">
            <div class="title">联系我们</div>
            <div class="tip">
                <span>* </span>
                <span class="name">姓名</span>
            </div>
            <el-input    class="input" v-model="form.nickname"></el-input>
            <div class="tip">
                <span>* </span>
                <span class="name">微信</span>
            </div>
            <el-input    class="input" v-model="form.weixin"></el-input>
            <div class="tip">
                <span>* </span>
                <span class="name">邮箱</span>
            </div>
            <el-input    class="input" v-model="form.mail"></el-input>
            <div class="tip">
                <span>* </span>
                <span class="name">手机号</span>
            </div>
            <div class="phone">
                <el-input    class="input" placeholder="请输入手机号" v-model="form.mobile" maxlength="11">
                    <template slot="prepend">86｜</template>
                </el-input>
                <el-input   class="input" placeholder="请输入验证码" v-model="form.code" maxlength="6">
                    <template slot="append">
                        <div @click="getSms" class="smsButton">{{ smsText }}</div>
                    </template>
                </el-input>
            </div>
            <div class="tip">
                <span>* </span>
                <span class="name">主题</span>
            </div>
            <div class="themeBox" @click="theme = !theme">
                {{ themeText() }}
                <i class="icon el-icon-arrow-down" :class="theme ? 'up' : '' "></i>
                <div class="themeList" v-if="theme">
                    <div class="type" @click.stop="chooseType(1)">讲者</div>
                    <div class="type" @click.stop="chooseType(3)">志愿者</div>
                    <div class="type" @click.stop="chooseType(4)">合作伙伴</div>
                    <div class="type" @click.stop="chooseType(5)">其他</div>
                </div>
            </div>
            <!-- <el-input    class="input" v-model="form.theme_type"></el-input> -->
            <div class="tip">
                <span>* </span>
                <span class="name">信息</span>
            </div>
            <div class="textareaBox">
                <el-input  maxlength="150"  class="input info" type="textarea" v-model="form.content"></el-input>
                <div class="count" v-if="form.content">{{ form.content.length }}/150</div>
            </div>

            <div class="button" @click="submit">提交</div>

            <div class="iconList">
                <img src="./../../assets/img/contact1.png" alt="" class="firstImg">
                <img src="./../../assets/img/contact2.png" alt="">
                <img src="./../../assets/img/contact3.png" alt="">
                <img src="./../../assets/img/contact4.png" alt="">
                <img src="./../../assets/img/contact5.png" alt="">
                <img src="./../../assets/img/contact6.png" alt="">
                <img src="./../../assets/img/contact7.png" alt="">
                <img src="./../../assets/img/contact8.png" alt="">
                <img src="./../../assets/img/contact9.png" alt="">
            </div>

        </div>
    </div>
</template>
<script>
import {banner , contact, sms } from './../../api/api'
export default {
    data(){
        return {
            form : {
                nickname : '',
                mobile : '',
                code : '',
                weixin : '',
                mail : '',
                theme_type : '',
                content : '',
            },
            data : {
                title : '',
                describe : '',
                url : '',
            },
            theme : false,
            load : false,
            smsText: '获取验证码',
            timer: '',
            numberCount: 0
        }
    },
    mounted(){
        banner({'seat' : 6}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        //获取验证码
        getSms() {
            if (this.numberCount > 0) return
            if(!this.form.mobile){
                this.$message.error('请先输入手机号')
                return
            }
            var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if(!myreg.test(this.form.mobile)){
                this.showMessage('请输入正确的手机号')
                return
            }
            var obj = {}
            obj.mobile = this.form.mobile
            obj.event = 1
            sms(obj).then(res => {
                this.numberCount = 60
                this.$message({ message: '验证码已发送', type: 'success' })
                this.timer = setInterval(() => {
                    if (this.numberCount <= 1) {
                        clearInterval(this.timer)
                        this.numberCount = 0
                        this.smsText = '获取验证码'
                        return
                    }
                    this.numberCount--
                    this.smsText = this.numberCount + 's'
                }, 1000)
            })
        },
        chooseType(type){
            this.form.theme_type = type
            this.theme = false
        },
        submit(){
            if(!this.form.weixin || !this.form.mobile || !this.form.code || !this.form.nickname || !this.form.mail || !this.form.theme_type  || !this.form.content ){
                this.$message.error('填完必填信息后，才能提交哦～')
                return
            }
            contact(this.form).then(res => {
                if (res.data.code === '10000') {
                    this.$message({ message: '申请成功', type: 'success' })
                    this.form = {}
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
    },
    computed : {
        themeText(){
            return () => {
                if(this.form.theme_type == 1){
                    return '讲者'
                }else if(this.form.theme_type == 2){
                    return '观众'
                }else if(this.form.theme_type == 3){
                    return '志愿者'
                }else if(this.form.theme_type == 4){
                    return '合作伙伴'
                }else if(this.form.theme_type == 5){
                    return '其他'
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.contact{
    background: #1C1C1C;
    padding-bottom: 150px;
    .textareaBox{
        position: relative;
        .count{
            position: absolute;
            bottom: -25px;
            right: 0;
            font-size: 14px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 20px;
        }
    }
    .themeBox{
        width: 770PX;
        height: 40px;
        background: rgba(216,216,216,0.3);
        border-radius: 10px;
        border: 1px solid #FFFFFF;
        margin: 10px auto 0;
        position: relative;
        font-size: 18px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        color: #FFFFFF;
        line-height: 38px;
        padding: 0;
        text-indent: 14px;
        .icon{
            line-height: 38px;
            color: #fff;
            position: absolute;
            right: 25px;
            top: 0;
            transition: 0.2s;
            text-indent: 0;
        }
        .up{
            transform: rotate(180deg);
        }
        .themeList{
            width: 770PX;
            height: 200px;
            background: #000000;
            border-radius: 10px;
            border: 1px solid #FFFFFF;
            position: absolute;
            left: -1px;
            top: 38px;
            z-index: 999;
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 50px;
            text-indent: 14px;
            cursor: pointer;
        }
    }
    .content{
        width: 770PX;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-direction: column;
        .iconList{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 80px;
            img{
                width: 47px;
                height: 34px;
                margin-left: 21px;
            }
            .firstImg{
                margin: 0;
            }
        }
        .button{
            width: 106px;
            height: 50px;
            background: #EB0028;
            border-radius: 10px;
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_85_Bold;
            color: #FFFFFF;
            line-height: 50px;
            text-align: center;
            margin-top: 50px;
            cursor: pointer;
        }
        
        .title{
            text-align: center;
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            margin-top: 90px;
            margin-bottom: 20px;
        }
        .input{
            width: 770px;
            height: 40px;
            background: rgba(216,216,216,0.3);
            border-radius: 10px;
            border: 1px solid #FFFFFF;
            margin: 10px auto 0;
            
            /deep/ .el-input__inner , /deep/ .el-textarea__inner{
                height: 100%;
                background: transparent;
                outline: none;
                border: none;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 38px;
                padding: 0 14px;
                resize: none;
            }
            /deep/ .el-textarea__inner{
                line-height: 30px;
            }
            /deep/ .el-input-group__prepend{
                background: transparent;
                border: none;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 38px;
                padding: 0;
                text-indent: 14px;
            }
        }
        .info{
            height: 120px;
            margin: 0;
        }
        .tip{
            font-size: 20px;
            font-family: YouSheBiaoTiHei;
            color: #EB0028;
            line-height: 40px;
            text-align: left;
            width: 770PX;
            margin-top: 30px;
            .name{
                color: #fff;
            }
        }
        .phone{
            display: flex;
            .input{
                width: 368px;
            }
            .input:nth-of-type(1){
                /deep/ .el-input__inner{
                    text-indent: 0;
                    padding: 0;
                }
            }
            .input:nth-of-type(2){
                margin-left: 34px;
                /deep/ .el-input-group__append{
                    width: 103px;
                    height: 29px;
                    background: #D8D8D8;
                    border-radius: 8px;
                    font-size: 18px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #1C1C1C;
                    line-height: 29px;
                    border: none;
                    padding: 0;
                    text-align: center;
                    display: block;
                    position: absolute;
                    right: 13px;
                    top: 50%;
                    margin-top: -15px;
                    cursor: pointer;
                }
            }
        }
    }
    .videoBox{
        // background-image: url('./../../assets/img/contactBanner.png');
        video{
            top: unset;
            bottom: 0;
        }
    }
}
</style>