<template>
    <div class="Mjoin">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true" ></video>
        </div>

        <div class="joinContent">
            <div class="title">参与我们</div>
            <div class="type">
                <div class="typeBox" @click="nextPage(1)">
                    <img src="./../../assets/img/join1.png" alt="">
                    <div class="typeName radius" >成为讲者</div>
                </div>
                <div class="typeBox" @click="nextPage(2)">
                    <img src="./../../assets/img/join2.png" alt="">
                    <div class="typeName radius" >合作伙伴</div>
                </div>
                <div class="typeBox" @click="nextPage(3)">
                    <img src="./../../assets/img/join3.png" alt="">
                    <div class="typeName radius" >志愿者</div>
                </div>
                <div class="typeBox" @click="nextPage(4)">
                    <img src="./../../assets/img/join4.png" alt="">
                    <div class="typeName radius" >观众</div>
                </div>
            </div>
        </div>

        <div class="QA">
            <div class="title">Q&A</div>
            <div class="list" v-for="(item , index) in list" :key="index" :class="index == 0 ? 'first' : '' ">
                <div class="Q">
                    <img src="./../../assets/img/QA.png" alt="">
                    <span>{{item.question}}</span>
                </div>
                <div class="A">
                    <div class="text">{{item.answer}}</div>
                    <img src="./../../assets/img/sanya2.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {banner} from './../../api/api'
export default {
    data(){
        return {
            list : [
                {question : '-成为讲者可以收获什么？' , answer : '您将把自己的创新想法和观点呈现给三亚乃至世界。'},
                {question : '-成为合作伙伴可以收获什么？' , answer : '增加品牌在全国和全球的曝光度，收获千万级流量。'},
                {question : '-成为志愿者可以收获什么？' , answer : '参与日常活动的执行，结识更多同频伙伴，收获志愿者证书。'},
                {question : '-成为观众可以收获什么？' , answer : '将用最短的时间收获各领域最前沿的创新想法和研究，并成为社区一员。'},
            ],
            data : {
                title : '',
                describe : '',
                video_url : '',
            },
            load : false,
        }
    },
    mounted(){
        banner({'seat' : 4}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                setTimeout(() => {
                    this.load = true
                } , 800)
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        nextPage(type){
			if(type == 1){
				this.$router.push('/Mspeaker')
			}else if(type == 2){
				this.$router.push('/Mpartner')
			}else if(type == 3){
				this.$router.push('/Mvolunteer')
			}else if(type == 4){
				this.$router.push('/Maudience')
			}
		},
    }
}
</script>
<style lang="less" scoped>
.Mjoin{
    background: #1C1C1C;
    text-align: center;
    padding-bottom: 50px;
    .QA{
        width: 333px;
        margin: 48px auto 0;
        .title{
            font-size: 26px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 34px;
        }
        .list{
            font-size: 14px;
            font-family: AlibabaPuHuiTi_2_85_Bold;
            color: #FFFFFF;
            line-height: 26px;
            overflow: hidden;
            margin-top: 38px;
            .Q{
                display: flex;
                align-items: center;
                img{
                    width: 28px;
                    height: 29px;
                    margin-right: 6px;
                }
            }
            .A{
                float: right;
                display: flex;
                align-items: center;
                margin-top: 10px;
                .text{
                    width: 221px;
                    text-align: left;
                }
                img{
                    width: 29px;
                    height: 39px;
                    margin-left: 6px;
                }
            }
        }
        .first{
            margin-top: 24px;
        }
    }
    .joinContent{
        margin-top: 24px;
        .type{
            margin-top: 16px;
            .typeBox{
                width: 331px;
                height: 232px;
                position: relative;
                overflow: hidden;
                margin: 24px auto 0;
                border-radius: 8px;
                img{
                    width: 100%;
                }
                .typeName{
                    width: 107px;
                    height: 52px;
                    background: rgba(56,56,56,0.2);
                    border: 1px solid #FFFFFF;
                    font-size: 22px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 50px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50% , -50%);
                }
            }
            .typeBox:nth-of-type(1){
                margin-top: 0;
            }
            .typeBox:nth-of-type(3){
                .typeName{
                    width: 86px;
                }
            }
            .typeBox:nth-of-type(4){
                .typeName{
                    width: 66px;
                }
            }
            .typeBox:active{
                img{
                    transform: scale(1.1);
                    transition: 0.2s;
                }
                .model{
                    color: #fff;
                }
            }
        }
        .title{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
        }
    }
}
</style>