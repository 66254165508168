<template>
    <div class="video">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" autoplay loop muted ></video>
        </div>
        <div class="preheat">
            <div class="title">嘉宾预热视频</div>
            <div class="videoContent">
                <el-carousel  ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                    <el-carousel-item v-for="index in Math.ceil(videoHot.length / 2)" :key="index">
                        <div class="Box radius" @click="playing">
                            <video @play="videoPlay" class="radius videoPlayer" :poster="videoHot[ 2 * ( index - 1)].cover" :src="videoHot[ 2 * ( index - 1)].url" controlslist="nodownload" disablePictureInPicture></video>
                            <div class="name radius">{{ videoHot[ 2 * ( index - 1)].nickname }}</div>
                        </div>
                        <div class="Box center radius"  @click="playing"  v-if="videoHot.length > 2 * ( index - 1) + 1 ">
                            <video @play="videoPlay" class="radius videoPlayer" :poster="videoHot[ 2 * ( index - 1) + 1].cover" :src="videoHot[ 2 * ( index - 1) + 1].url" controlslist="nodownload" disablePictureInPicture></video>
                            <div class="name radius">{{ videoHot[ 2 * ( index - 1) + 1].nickname }}</div>
                        </div>
                        <div class="Box center radius" v-else>
                            <div class="cover"></div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div class="Speaker">
            <div class="title">TEDxSanya讲者视频</div>
            <div class="contentBox">
                <div class="SpeakerBox" v-for="(item , index) in videoList" :key="index"  @click="showVideo(item)">
                    <!-- <img :src="item.cover" alt="" class="bg"> -->
                    <video @play="videoPlay"  :poster="item.cover" :src="item.url" controlslist="nodownload" disablePictureInPicture class="radius videoPlayer"></video>
                    <div class="titleContent radius">{{ item.title }}</div>
                    <!-- <div class="model">
                        <div class="say">{{item.describe}}</div>
                        <div class="name">{{item.nickname}}</div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {banner , video} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            videoList : [],
            videoHot : [],
            load : false,
            playTarget : ''
        }
    },
    mounted(){
        banner({'seat' : 3}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
        this.getVideoList(2)
        this.getVideoList(3)
    },
    methods : {
        videoPlay(e){
            var videoList = document.querySelectorAll('.videoPlayer')
            for(var i = 0 ; i < videoList.length ; i ++){
                if(!videoList[i].paused && videoList[i] != e.target){
                    videoList[i].pause()
                    break
                }
            }
        },
        playing(e){
            this.playTarget = e.currentTarget
            if(this.playTarget.children[0].getAttribute('controls')){
                return
            }
            this.playTarget.children[0].setAttribute('controls' , true)
            this.playTarget.children[1].style.display = 'none'
            setTimeout(() => {
                this.playTarget.children[0].play()
            } , 100)
        },
        getVideoList(type){
            video({
                'type': type
            }).then(res => {
                if(type == 2){
                    this.videoHot = res.data.data
                }else if(type == 3){
                    this.videoList = res.data.data
                }
            })
        },
        showVideo(item){
            if(!item.url){
                this.$message({ message: '敬请期待～', type: 'success' })
            }else{
                var e = window.event
                this.playTarget = e.currentTarget
                if(this.playTarget.children[0].getAttribute('controls')){
                    return
                }
                this.playTarget.children[0].setAttribute('controls' , true)
                this.playTarget.children[1].style.display = 'none'
                setTimeout(() => {
                    this.playTarget.children[0].play()
                } , 100)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.video{
    background: #1C1C1C;
    .Speaker{
        width: 1126px;
        margin: 80px auto 0;
        text-align: center;
        padding-bottom: 140px;
        overflow: hidden;
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
        }
        .carousel{
            height: 960px;
            /deep/ .el-carousel__container{
                height: 100%;
            }
        }
        .SpeakerBox{
            width: 536px;
            height: 302px;
            position: relative;
            overflow: hidden;
            float: left;
            margin-top: 30px;
            cursor: pointer;
            video{
                width: 100%;
                height: 100%;
            }
            .model{
                width: 100%;
                height: 75px;
                background: rgba(0,0,0,0.7);
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_85_Bold;
                color: #EB0028;
                line-height: 26px;
                position: absolute;
                bottom: 0;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }
            .titleContent{
                height: 52px;
                background: rgba(56,56,56,0.2);
                border: 1px solid #FFFFFF;
                font-size: 30px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 50px;
                padding: 0 34px;
                position: absolute;
                top: 124px;
                left: 50%;
                white-space: nowrap;
                transform: translateX(-50%);
            }
            .bg{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 0;
            }
        }
        .SpeakerBox:nth-of-type(2n){
            margin-left: 54px;
        }
    }
    .preheat{
        width: 1126px;
        margin: 80px auto 0;
        text-align: center;
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
        }
    }
    .videoContent{
        margin-top: 24px;
        .carousel{
            height: 325px;
            /deep/ .el-carousel__container{
                height: 302px;
            }
            /deep/ .el-carousel__indicator--horizontal{
                padding: 15px 4px 0;
            }
            .Box{
                width: 536px;
                height: 302px;
                float: left;
                position: relative;
                cursor: pointer;
                .cover{
                    width: 100%;
                    height: 100%;
                    // background: #9F9F9F;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }
                video{
                    width: 100%;
                    height: 100%;
                }
                .name{
                    height: 52px;
                    background: rgba(56,56,56,0.2);
                    border: 1px solid #FFFFFF;
                    padding: 0 36px;
                    font-size: 30px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 50px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50% , -50%);
                }
            }
            .center{
                margin-left: 54px;
            }
        }
    }
}

</style>