<template>
    <div class="Mabout">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true"></video>
            <!-- <video :src="data.url" ref="video" loop muted webkit-playsinline="true" playsinline="true"></video> -->
        </div>
        <div class="team">
            <div class="title">团队</div>
            <img src="./../../assets/img/team.png" alt="">
            <div class="name">Hang Qin</div>
            <div class="name">TEDxSanya大会策展负责人</div>
        </div>

        <div class="content">
            <div class="ted">
                <div class="title">关于TED</div>
                <img class="radius" src="./../../assets/img/about1.png" alt="">
                <span class="credit">Credit to: TED.com</span>
                <div class="text">
                    <span class="color">TED</span>
                    大会在1984年创立于加州，发展至今，大会传播分享的内容涵盖科学、商业、艺术、环境气候等领域。
                    <span class="color">TED</span>
                    以 Ideas Worth Spreading 为宗旨，通过精炼有力量的十几分钟演讲在全世界范围内传播有价值的思想，激发有意义的对话。
                </div>
            </div>
            <div class="ted">
                <div class="title">关于TEDx</div>
                <img class="radius" src="./../../assets/img/about2.png" alt="">
                <span class="credit">Credit to: TEDxWinterPark</span>
                <div class="text">
                    2009年TED发展出了
                    <span class="color">TEDx</span>
                    项目。x代表了在TED总部授权下独立组织的策展活动，
                    <span class="color">TEDx</span>
                    活动在全球各地开展，发现并传播有价值的观点和思想。
                </div>
            </div>
            <div class="ted">
                <div class="title">关于TEDxSanya</div>
                <img class="radius" src="./../../assets/img/about3.png" alt="">
                <div class="text">
                    <span class="color">TEDxSanya</span>
                    是经TED总部官方授权的全球TEDx社区成员，作为一个城市级TEDx组织，我们始于海南三亚，通过开展演讲活动、共创
                    <span class="color">TEDxSanya</span>
                    社区等方式，面向全国以及全球分享并传播有价值的创新观点。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                video_url : '',
            },
            load : false
        }
    },
    mounted(){
        banner({'seat' : 5}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                setTimeout(() => {
                    this.load = true
                } , 800)
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
}
</script>

<style lang="less" scoped>
.Mabout{
    background: #1C1C1C;
    text-align: center;
    padding-bottom: 62px;
    .content{
        .ted{
            margin: 42px auto 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 333px;
            .title{
                font-size: 26px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 34px;
            }
            img{
                width: 333px;
                height: 163px;
                display: block;
                margin-top: 16px;
            }
            .credit{
                font-size: 10px;
                font-family: YouSheBiaoTiHei;
                color: #EB0028;
                line-height: 13px;
                text-align: right;
                display: block;
                width: 100%;
            }
            .text{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #fff;
                line-height: 26px;
                margin-top: 14px;
                text-align: left;
                margin-left: 4px;
                width: 331px;
                .color{
                    color: #EB0028;
                }
            }
        }
    }
    .team{
        margin-top: 39px;
        .title{
            font-size: 26px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 34px;
        }
        img{
            width: 200px;
            height: 201px;
            margin-top: 22px;
            margin-bottom: 14px;
        }
        .name{
            font-size: 20px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 32px;
        }
    }
    .MvideoBox{
        // background-image: url('./../../assets/img/aboutBanner.png');
        video{
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>