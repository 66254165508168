<template>
    <div class="speaker">
        <div class="info">
            <div class="title">成为讲者</div>
            <div class="dec">欢迎申请成为TEDxSanya的讲者！讲者们将通过18分钟以内的思想传递，让更多的人打破固有的思维边界，我们期待和寻找这样的观点：</div>
            <div class="list">
                <span>· 固有思维中诞生的新颖视角</span>
                <span>· 各行业领域中的颠覆性革新</span>
                <span>· 充满趣味，启发人心</span>
                <span>· 有助于提升认知、改善生活</span>
            </div>
        </div>

        <div class="form">
            <div class="tip">
                <span>* </span>
                <span class="name">讲者的姓名</span>
            </div>
            <el-input    class="input" v-model="form.uname"></el-input>
            <div class="tip">
                <span>* </span>
                <span class="name">讲者的微信号</span>
            </div>
            <el-input    class="input" v-model="form.weixin"></el-input>
            <div class="tip">
                <span>* </span>
                <span class="name">手机号</span>
            </div>
            <div class="phone">
                <el-input  type="number"    class="input" placeholder="请输入手机号" v-model="form.mobile"  maxlength="11">
                    <template slot="prepend">86｜</template>
                </el-input>
                <el-input   type="number"   class="input" placeholder="请输入验证码" v-model="form.code"  maxlength="6">
                    <template slot="append">
                        <div @click="getSms" class="smsButton">{{ smsText }}</div>
                    </template>
                </el-input>
            </div>
            <div class="tip">
                <span>* </span>
                <span class="name">讲者的邮箱</span>
            </div>
            <div class="dec">请防止邮件被过滤或屏蔽</div>
            <el-input    class="input" v-model="form.mail"></el-input>
            
            <div class="questionList" v-for="item in questionList" :key="item.question_id">
                <div class="tip">
                    <span  :class="item.required == 1 ? '' : 'bgColor' ">* </span>
                    <span class="name">{{ item.title }}</span>
                </div>
                <div class="dec">{{ item.describe }}</div>
                <div class="textareaBox">
                    <el-input  maxlength="150"  class="input " :class="item.size == 2 ? 'info' : '' "  :type="item.size == 2 ? 'textarea' : '' " v-model="item.content"></el-input>
                    <div class="count" v-if="item.content">{{ item.content.length }}/150</div>
                </div>
            </div>


        </div>

        <div class="submit">
            <div class="text">感谢关注！我们将在收到申请后第一时间审核，还请耐心等待～</div>
            <div class="button" @click="submit">提交</div>
        </div>
    </div>
</template>
<script>
import {question , talker_apply , sms } from './../../api/api'
export default {
    data(){
        return {
            form : {
                mobile : '',
                code : '',
                uname : '',
                weixin : '',
                mail : '',
                question : '',
            },
            questionList : [],
            smsText: '获取验证码',
            timer: '',
            numberCount: 0
        }
    },
    methods : {
        //获取验证码
        getSms() {
            if (this.numberCount > 0) return
            if(!this.form.mobile){
                this.$message.error('请先输入手机号')
                return
            }
            var obj = {}
            obj.mobile = this.form.mobile
            obj.event = 1
            sms(obj).then(res => {
                this.numberCount = 60
                this.$message({ message: '验证码已发送', type: 'success' })
                this.timer = setInterval(() => {
                    if (this.numberCount <= 1) {
                        clearInterval(this.timer)
                        this.numberCount = 0
                        this.smsText = '获取验证码'
                        return
                    }
                    this.numberCount--
                    this.smsText = this.numberCount + 's'
                }, 1000)
            })
        },
        submit(){
            if(!this.form.weixin || !this.form.mobile || !this.form.code || !this.form.uname || !this.form.mail){
                this.$message.error(' 填完必填信息后，才能提交哦～')
                return
            }
            var arr = []
            for(var i = 0 ; i < this.questionList.length ; i ++){
                if(!this.questionList[i].content && this.questionList[i].required == 1){
                    this.$message.error(' 填完必填信息后，才能提交哦～')
                    return
                }
                var obj = {}
                obj.question_id = this.questionList[i].question_id
                obj.content = this.questionList[i].content
                obj.extend_content = this.questionList[i].extend_content || ''
                arr.push(obj)
            }
            
            this.form.question = JSON.stringify(arr)
            talker_apply(this.form).then(res => {
                if (res.data.code === '10000') {
                    this.$message({ message: '申请成功', type: 'success' })
                    this.form = {}
                    this.questionList.forEach(item => {
                        item.content = ''
                    })
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        getQuestion(){
            question({'role' : 1}).then(res => {
                if (res.data.code === '10000') {
                    this.questionList = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        }
    },
    mounted(){
        this.getQuestion()
    }
}
</script>
<style lang="less" scoped>
.speaker{
    background: #1C1C1C;
    overflow: hidden;
    padding-bottom: 70px;
    .textareaBox{
        position: relative;
        .count{
            position: absolute;
            bottom: -14px;
            right: 0;
            font-size: 8px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 11px;
        }
    }
    .submit{
        width: 331px;
        margin: 40px auto 0;
        .text{
            font-size: 14px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 26px;
            
        }
        .button{
            width: 74px;
            height: 40px;
            background: #EB0028;
            border-radius: 8px;
            font-size: 14px;
            font-family: AlibabaPuHuiTi_2_85_Bold;
            color: #FFFFFF;
            line-height: 40px;
            margin: 40px auto 0;
            text-align: center;
        }
    }
    .form{
        width: 331px;
        margin: 30px auto 0;
        .input{
            width: 331px;
            height: 36px;
            background: rgba(216,216,216,0.3);
            border-radius: 6px;
            border: 1px solid #FFFFFF;
            margin-top: 10px;
            /deep/ .el-input__inner  , /deep/ .el-textarea__inner{
                height: 100%;
                background: transparent;
                outline: none;
                border: none;
                font-size: 12px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 34px;
                padding: 0;
                text-indent: 14px;
                resize: none;
            }
            /deep/ .el-input-group__prepend{
                background: transparent;
                border: none;
                font-size: 12px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 34px;
                padding: 0;
                text-indent: 14px;
            }
            /deep/ .el-input-group__append{
                width: 69px;
                height: 22px;
                background: #D8D8D8;
                border-radius: 6px;
                font-size: 12px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #1C1C1C;
                line-height: 22px;
                border: none;
                padding: 0;
                text-align: center;
                display: block;
                position: absolute;
                right: 6px;
                top: 50%;
                margin-top: -11px;
                cursor: pointer;
            }
        }
        .info{
            height: 72px;
            /deep/ .el-textarea__inner{
                line-height: 20px;
            }
        }
        .tip{
            font-size: 14px;
            font-family: YouSheBiaoTiHei;
            color: #EB0028;
            line-height: 18px;
            margin-top: 30px;
            display: flex;
            .name{
                color: #fff;
                display: block;
                margin-left: 5px;
            }
        }
        .dec{
            font-size: 10px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #FFFFFF;
            line-height: 14px;
            margin-top: 5px;
            margin-left: 11px;
        }
        .phone{
            .input:nth-of-type(1){
                /deep/ .el-input__inner{
                    text-indent: 0;
                    padding: 0;
                }
            }
        }
        .file{
            margin-top: 10px;
            /deep/ .el-input__inner{
                padding: 0;
            }
            /deep/ .el-button{
                width: 76px;
                height: 36px;
                background: rgba(216,216,216,0.3);
                border-radius: 6px;
                border: 1px solid #FFFFFF;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 34px;
                padding: 0;
            }
        }
    }
    .info{
        width: 331px;
        margin: 40px auto 0;
        .title{
            font-size: 26px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 34px;
            text-align: center;
        }
        .dec{
            font-size: 14px;
            font-family: AlibabaPuHuiTi_2_85_Bold;
            color: #FFFFFF;
            line-height: 26px;
            margin-top: 30px;
        }
        .list{
            font-size: 14px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 26px;
            margin-top: 30px;
            span{
                display: block;
            }
        }
    }
}
</style>