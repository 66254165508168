import { render, staticRenderFns } from "./speaker.vue?vue&type=template&id=c1dc0266&scoped=true&"
import script from "./speaker.vue?vue&type=script&lang=js&"
export * from "./speaker.vue?vue&type=script&lang=js&"
import style0 from "./speaker.vue?vue&type=style&index=0&id=c1dc0266&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1dc0266",
  null
  
)

export default component.exports